<template>
	<modal class="NoxModalVPN" name="NoxModalVPN" modal-width="600" :width="600" :height="'auto'" :adaptive="true" :scrollable="true" :clickToClose="true" @before-open="beforeOpen">
		<div class="nox_modal">
			<div class="nox_modal_head">
				<div class="nox_modal_close" @click="close"><font-awesome-icon :icon="['fas', 'times']" /></div>
				<div class="nox_modal_title">
					<span v-if="noxType == 'buyVPNSubscription'" v-html="$store.getters.getLanguageText('5.21', 0, { options: [0] })"></span>
					<span v-else-if="noxType == 'extendVPNSubscription'" v-html="$store.getters.getLanguageText('5.21', 0, { options: [1] })"></span>
				</div>
			</div>
			<div class="nox_modal_body">
				<div class="nox_modal_info">
					<div v-if="['buyVPNSubscription', 'extendVPNSubscription'].includes(noxType)">
						<div v-if="noxStep == 1">
							<p v-html="$store.getters.getLanguageText('5.21', 1)"></p>
							<p><span v-html="$store.getters.getLanguageText('5.16', 7, { balance: $parent.$parent.$parent.getNumberFormatForDollar(noxBalance1, 2) })"></span><br><span v-html="$store.getters.getLanguageText('5.16', 8, { balance: $parent.$parent.$parent.getNumberFormatForDollar(noxBalance3, 2) })"></span></p>
							<p v-html="$store.getters.getLanguageText('5.21', 2)"></p>
							<p class="nox_p_radios">
								<label class="nox_radio" :for="'nox_radio_vpn_subscription_period_' + index" v-for="(value, index) in noxVPNSubscriptionPeriods" :key="index">
									<input type="radio" :id="'nox_radio_vpn_subscription_period_' + index" name="nox_radio_vpn_subscription_period" :value="value" v-model.number="noxVPNSubscriptionPeriod">
									<span class="nox_radio_mark"></span>
									<span class="nox_radio_text"><span v-html="$store.getters.getLanguageText('5.21', 3, { options: [(value == 1 ? 0 : 2)], months: value, cost: parseFloat(value == 1 ? noxVPNSubscriptionPeriodM1TotalCost : (value == 6 ? noxVPNSubscriptionPeriodM6TotalCost : noxVPNSubscriptionPeriodM12TotalCost)).toFixed(2) })"></span></span>
								</label>
							</p>
							<p v-if="((noxVPNSubscriptionPeriod == 1 && !noxIsBuyM1) || (noxVPNSubscriptionPeriod == 6 && !noxIsBuyM6) || (noxVPNSubscriptionPeriod == 12 && !noxIsBuyM12))"><span class="red" v-html="$store.getters.getLanguageText('5.16', (noxType == 'buyVPNSubscription' ? 76 : 13), { amount: parseFloat((noxVPNSubscriptionPeriod == 1 ? noxVPNSubscriptionPeriodM1TotalCost : (noxVPNSubscriptionPeriod == 6 ? noxVPNSubscriptionPeriodM6TotalCost : noxVPNSubscriptionPeriodM12TotalCost)) - noxBalance).toFixed(2) })"></span></p>
						</div>
						<div v-else-if="noxStep == 2" v-html="$store.getters.getLanguageText('5.21', 4, { options: [(noxType == 'buyVPNSubscription' ? 0 : 1)], amount: parseFloat(noxVPNSubscriptionPeriod == 1 ? noxVPNSubscriptionPeriodM1TotalCost : (noxVPNSubscriptionPeriod == 6 ? noxVPNSubscriptionPeriodM6TotalCost : noxVPNSubscriptionPeriodM12TotalCost)).toFixed(2) })"></div>
					</div>
				</div>
				<div class="nox_modal_alert" v-html="noxAlert"></div>
				<div class="nox_modal_loading" :class="{ hide: !noxIsLoading }"><img src="@/assets/images/loading.gif"></div>
				<div class="nox_modal_buttons" :class="{ hide: noxIsLoading }">
					<button type="button" class="nox_button common" @click="noxStep--" v-if="['buyVPNSubscription', 'extendVPNSubscription'].includes(noxType) && noxStep > 1" v-html="$store.getters.getLanguageText('1.1', 55)"></button>
					<button type="button" class="nox_button common" @click="close" v-else v-html="$store.getters.getLanguageText('1.1', 3)"></button>
					<button type="button" class="nox_button common green" @click="noxStep++" v-if="['buyVPNSubscription', 'extendVPNSubscription'].includes(noxType) && noxStep == 1 && ((noxVPNSubscriptionPeriod == 1 && noxIsBuyM1) || (noxVPNSubscriptionPeriod == 6 && noxIsBuyM6) || (noxVPNSubscriptionPeriod == 12 && noxIsBuyM12))" v-html="$store.getters.getLanguageText('1.1', (noxType == 'buyVPNSubscription' ? 166 : 167))"></button>
					<button type="button" class="nox_button common green" @click="axios" v-else-if="['buyVPNSubscription', 'extendVPNSubscription'].includes(noxType) && noxStep == 2 && ((noxVPNSubscriptionPeriod == 1 && noxIsBuyM1) || (noxVPNSubscriptionPeriod == 6 && noxIsBuyM6) || (noxVPNSubscriptionPeriod == 12 && noxIsBuyM12))" v-html="$store.getters.getLanguageText('1.1', (noxType == 'buyVPNSubscription' ? 22 : 36))"></button>
					<button type="button" class="nox_button common green" @click="close();$modal.show('NoxModalBalance', { type: 'addMainBalance' })" v-else-if="['buyVPNSubscription', 'extendVPNSubscription'].includes(noxType) && ((noxVPNSubscriptionPeriod == 1 && !noxIsBuyM1) || (noxVPNSubscriptionPeriod == 6 && !noxIsBuyM6) || (noxVPNSubscriptionPeriod == 12 && !noxIsBuyM12))" v-html="$store.getters.getLanguageText('1.1', 100)"></button>
				</div>
			</div>
		</div>
	</modal>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxTemp: '',
			noxAlert: '',
			noxStep: 0,
			noxType: '',
			noxBalance: 0,
			noxBalance1: 0,
			noxBalance2: 0,
			noxBalance3: 0,
			noxSystemVat: 0,
			noxVPNSubscriptionPeriod: 0,
			noxVPNSubscriptionPeriods: [],
			noxVPNSubscriptionPeriodM1: 0,
			noxVPNSubscriptionPeriodM6: 0,
			noxVPNSubscriptionPeriodM12: 0,
			noxVPNSubscriptionPeriodM1Cost: 0,
			noxVPNSubscriptionPeriodM6Cost: 0,
			noxVPNSubscriptionPeriodM12Cost: 0,
			noxVPNSubscriptionPeriodM1TotalCost: 0,
			noxVPNSubscriptionPeriodM6TotalCost: 0,
			noxVPNSubscriptionPeriodM12TotalCost: 0,
			noxIsLoading: false,
			noxIsError: false,
			noxIsBuyM1: false,
			noxIsBuyM6: false,
			noxIsBuyM12: false
		}),
		methods: {
			beforeOpen: function(event) {

				this.noxTemp = '';
				this.resetAlerts();
				this.noxStep = 1;
				this.noxType = event.params.type;
				this.noxBalance = 0;
				this.noxBalance1 = 0;
				this.noxBalance2 = 0;
				this.noxBalance3 = 0;
				this.noxSystemVat = 0;
				this.noxVPNSubscriptionPeriod = 1;
				this.noxVPNSubscriptionPeriods = [1,6,12];
				this.noxVPNSubscriptionPeriodM1 = 0;
				this.noxVPNSubscriptionPeriodM6 = 0;
				this.noxVPNSubscriptionPeriodM12 = 0;
				this.noxVPNSubscriptionPeriodM1Cost = 0;
				this.noxVPNSubscriptionPeriodM6Cost = 0;
				this.noxVPNSubscriptionPeriodM12Cost = 0;
				this.noxVPNSubscriptionPeriodM1TotalCost = 0;
				this.noxVPNSubscriptionPeriodM6TotalCost = 0;
				this.noxVPNSubscriptionPeriodM12TotalCost = 0;
				this.noxIsLoading = false;
				this.noxIsError = false;
				this.noxIsBuyM1 = false;
				this.noxIsBuyM6 = false;
				this.noxIsBuyM12 = false;

				if (['buyVPNSubscription', 'extendVPNSubscription'].includes(this.noxType)) {

					this.noxBalance1 = Number(this.$store.state.noxAccountData.balance_nox);
					this.noxBalance2 = Number(this.$store.state.noxAccountData.balance_nox_zp);
					this.noxBalance3 = Number(this.$store.state.noxAccountData.balance_of_bonuses);
					this.noxBalance = Math.round((this.noxBalance1 + this.noxBalance3) * 100) / 100;
					this.noxSystemVat = Number(this.$store.state.noxSystemSettings.vat_value);
					this.noxVPNSubscriptionPeriodM1 = Number(this.$store.state.noxSystemSettings.vpn_subscription_period_m1);
					this.noxVPNSubscriptionPeriodM6 = Number(this.$store.state.noxSystemSettings.vpn_subscription_period_m6);
					this.noxVPNSubscriptionPeriodM12 = Number(this.$store.state.noxSystemSettings.vpn_subscription_period_m12);
					this.noxVPNSubscriptionPeriodM1Cost = Number(this.$store.state.noxSystemSettings.vpn_subscription_cost_m1);
					this.noxVPNSubscriptionPeriodM6Cost = Number(this.$store.state.noxSystemSettings.vpn_subscription_cost_m6);
					this.noxVPNSubscriptionPeriodM12Cost = Number(this.$store.state.noxSystemSettings.vpn_subscription_cost_m12);
					this.noxVPNSubscriptionPeriodM1TotalCost = this.noxVPNSubscriptionPeriodM1Cost * (1 + this.noxSystemVat / 100);
					this.noxVPNSubscriptionPeriodM6TotalCost = this.noxVPNSubscriptionPeriodM6Cost * (1 + this.noxSystemVat / 100);
					this.noxVPNSubscriptionPeriodM12TotalCost = this.noxVPNSubscriptionPeriodM12Cost * (1 + this.noxSystemVat / 100);
					this.noxVPNSubscriptionPeriodM1TotalCost = Math.round(this.noxVPNSubscriptionPeriodM1TotalCost * 100) / 100;
					this.noxVPNSubscriptionPeriodM6TotalCost = Math.round(this.noxVPNSubscriptionPeriodM6TotalCost * 100) / 100;
					this.noxVPNSubscriptionPeriodM12TotalCost = Math.round(this.noxVPNSubscriptionPeriodM12TotalCost * 100) / 100;
					this.noxIsBuyM1 = this.noxBalance >= this.noxVPNSubscriptionPeriodM1TotalCost ? true : false;
					this.noxIsBuyM6 = this.noxBalance >= this.noxVPNSubscriptionPeriodM6TotalCost ? true : false;
					this.noxIsBuyM12 = this.noxBalance >= this.noxVPNSubscriptionPeriodM12TotalCost ? true : false;
				}
			},
			resetAlerts: function() {
				this.noxAlert = '';
			},
			getError: function(i, data) {

				this.noxIsError = true;

				if      (i == 1) { this.noxTemp = this.$store.getters.getLanguageText('1.3.19', 0); }
				else if (i == 2) { this.noxTemp = this.$store.getters.getLanguageText('1.3.19', 1); }
				else if (i == 3) { this.noxTemp = this.$store.getters.getLanguageText('1.3.19', 2, data); }
				else if (i == 4) { this.noxTemp = this.$store.getters.getLanguageText('1.3.19', 3); }
				else             { this.noxTemp = this.$store.getters.getLanguageText('1.3', 2); }

				return '<div class="alert"><b>' + this.$store.getters.getLanguageText('1.3', 0) + '</b> ' + this.noxTemp + '</div>';
			},
			axios: function() {

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					_this.resetAlerts();
					_this.noxIsError = false;

					if (['buyVPNSubscription', 'extendVPNSubscription'].includes(_this.noxType)) {
						if ((_this.noxVPNSubscriptionPeriod == 1 && _this.noxIsBuyM1) || (_this.noxVPNSubscriptionPeriod == 6 && _this.noxIsBuyM6) || (_this.noxVPNSubscriptionPeriod == 12 && _this.noxIsBuyM12)) {
							config.url = '/v2/account/vpn/' + (_this.noxType == 'buyVPNSubscription' ? 'buy' : 'extend');
							config.data = { period: _this.noxVPNSubscriptionPeriod };
							config.method = _this.noxType == 'buyVPNSubscription' ? 'post' : 'patch';
						}
						else {
							_this.$parent.$parent.$parent.goToTop(true); _this.$router.push({ path: '/account/balance' }); return false;
						}
					}

					if (!_this.noxIsError) {
						_this.noxIsLoading = true;
						axios(config).then(function(data) {
							if (data.status == 200) {
								if (data.data.account_data !== undefined) {
									_this.$parent.$parent.$parent.initAccountData(data.data.account_data);
								}
								_this.close();
							}
						}).catch(function(data) {
							if (data.response !== undefined) {
								if (data.response.status == 400) {
									if      (data.response.data.error == 'PERIOD_IS_EMPTY') { _this.noxAlert = _this.getError(1); }
									else if (data.response.data.error == 'PERIOD_NOT_VALID') { _this.noxAlert = _this.getError(2); }
								}
								else if (data.response.status == 401) { _this.$parent.$parent.$parent.exit(); }
								else if (data.response.status == 403) { _this.$parent.$parent.$parent.exit(); }
								else if (data.response.status == 409) {
									if      (data.response.data.error == 'NEGATIVE_BALANCE') { _this.noxAlert = _this.getError(3, { options: [(_this.noxType == 'buyVPNSubscription' ? 0 : 1)] }); }
									else if (data.response.data.error == 'NOT_ENOUGH_MONEY') { _this.noxAlert = _this.getError(4); }
								}
							}
							if (!_this.noxIsError) {
								_this.noxAlert = _this.getError(0);
							}
						}).finally(function() {
							_this.noxIsLoading = false;
						});
					}
				}
				else {
					_this.$parent.$parent.$parent.exit();
				}
			},
			close: function() {
				this.$modal.hide('NoxModalVPN');
			}
		}
	}
</script>
